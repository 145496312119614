
import api from '@/api/co.api'
import app from '@/api/co.app'
import lib from '@/api/co.lib'
import activity from '@/api/co.activity'

// const BASE64 = require('@/api/lib/base64.js')

const ActTypes = activity.comm.Types

export default {
  data () {
    return {
      actInfo: {
        activityId: '',
        title: ''
      },
      btnPutin: {
        text: '活动报名',
        disabled: false,
        visible: false,
        loading: false
      },
      btnSubmit: {
        text: '提交',
        disabled: false,
        visible: false,
        loading: false
      },
      valid: true,
      wdName: {
        label: '联系人',
        value: '',
        hint: '请输入真实姓名，便于服务人员联系',
        counter: 32,
        rules: [
          v => !!v || '必须输入联系人姓名',
          v => (v && v.length <= 32) || '联系人姓名不能超过32个字符',
        ]
      },
      wdPhone: {
        label: '联系电话',
        value: '',
        hint: '请输入11位联系人手机号码',
        counter: 11,
        rules: [
          v => !!v || '必须输入联系人手机号码',
          v => /^[1][3-9][0-9]{9}$/.test(v) || '必须输入11位有效手机号码',
        ]
      },
      wdCompany: {
        label: '公司名称',
        value: '',
        hint: '便于我们更好地为你提供服务请填写公司名称',
        counter: 32,
        rules: [
          v => (!v || (v && v.length <= 32)) || '公司名称不能超过32个字符',
        ]
      },
      wdRemark: {
        label: '说明',
        value: '',
        hint: '请输入辅助说明，便于工作人员与你沟通',
        counter: 128,
        rules: [
          v => (!v || (v && v.length <= 128)) || '说明不能超过64个字符',
        ]
      },
      wdFiles: {
        label: '上传申报文件',
        accept: '.pdf,.doc,.docx,.xls,.xlsx',
        hint: '申报文本仅支持PDF、Word、Excel文件',
        multiple: true,
        rules: []
      },
      pages: {
        [ActTypes.ACTIVITY_REGISTER]: api.page.registration.name,
        [ActTypes.PROJECT_REPORT]: api.page.reporting.name,
      }
    }
  },
  methods: {
    viewAccessory (ev) {
      // console.log('ev: %o', ev)
      // const enUrl = encodeURIComponent(BASE64.encode(ev.url))
      // const opUrl = `${app.base.office_url}/onlinePreview?url=${enUrl}`
      // window.open(opUrl, '_target')
      api.comm.viewFile(ev)
    },
    toSave () {
      const userInfo = {
        name: this.wdName.value,
        phone: this.wdPhone.value,
        company: this.wdCompany.value
      }

      const data = {
        activityId: this.actInfo.activityId,
        siteId: app.init.siteId,
        remark: this.wdRemark.value,
        userInfo: JSON.stringify(userInfo)
      }

      const executing = function () {

      }
      const executed = function (res) {}

      api.http.push({
        url: '',
        data,
        executing,
        executed
      })
    },
    submit () {
      const result = this.$refs.form.validate()
      if (!result) {
        this.showError('请完善必须填写的内容')
        return
      }

      this.toSave()
    },
    clear () {
      this.$refs.form.reset()
    },
    onPutin (ev) {
      let name = ''
      const type = this.actInfo.type
      name = this.pages[type] || ''
      if (!name) {
        return
      }

      const params = {
        activityId: this.actInfo.activityId,
        type,
      }

      lib.cookie.set(name, JSON.stringify(params))
      this.$router.push({ name })
    },
    getActivity (actId) {
      if (!actId) {
        console.warn('mix.activity getActivity, actId is empty!!!')
        return
      }
      const me = this
      const executing = function () {
        me.$set(me.btnSubmit, 'loading', true)
      }

      const executed = function (ev) {
        console.log('mix.activity executed, ev: %o', ev)
        me.$set(me.btnSubmit, 'loading', false)
        if (ev.status) {
          me.actInfo = ev.data
          const btn = me.btnPutin
          btn.text = me.actInfo.buttonText
          // btn.disabled = me.actInfo.buttonDisabled
        }
      }

      activity.main.getDetail({
        id: actId,
        executing,
        executed
      })
    }
  }
}
