<template>
  <section>
    <land-hero-alts
      v-if="banItems.length"
      :items="banItems"
      :assist-color="banApp.assistColor"
      :divisible="banApp.divisible"
      :breadcrumbs="banApp.crumbs"
    />
    <land-section
      id="activity-detail"
      space="48"
    >
      <v-container
        style="max-width: 800px;"
      >
        <v-row
          no-gutters
          justify="center"
        >
          <v-col
            cols="12"
          >
            <div class="d-flex flex-column">
              <v-card>
                <div class="d-flex flex-column justify-center align-center pa-6">
                  <span class="text-h6">{{ actInfo.title }}</span>
                  <span class="text-body-1 blue-grey--text darken-4 mt-1">{{ actInfo.timelimit }}</span>
                </div>

                <div class="px-6 py-2">
                  <p v-html="actInfo.content" />
                </div>
              </v-card>

              <div
                v-if="actInfo.accessory && actInfo.accessory.length"
                class="d-flex flex-column co-w-full mt-10"
              >
                <land-navigation
                  title="附件"
                  size="text-lg-h6"
                />
                <div class="d-flex flex-column">
                  <div
                    v-for="(acc, accIndex) in actInfo.accessory"
                    :key="`acc_${accIndex}`"
                    class="d-flex flex-row mt-4 blue-grey lighten-5 px-5 py-3 rounded"
                  >
                    <v-hover
                      v-slot="{ hover }"
                      close-delay="120"
                    >
                      <a
                        :class="['text-subtitle-1', 'd-inline-block', 'text-truncate', hover ? 'primary--text' : 'black--text darken-4'
                        ]"
                        style="flex: 1;"
                        @click="viewAccessory(acc)"
                      >
                        {{ acc.name }}
                      </a>
                    </v-hover>
                  </div>
                </div>
              </div>

              <div class="d-flex flex-column justify-center align-center mt-8">
                <v-btn
                  rounded
                  depressed
                  color="primary"
                  width="400"
                  height="48"
                  :disabled="btnPutin.disabled"
                  :loading="btnPutin.loading"
                  @click="onPutin"
                >
                  {{ btnPutin.text }}
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </land-section>
  </section>
</template>

<script>
  import api from '@/api/co.api'
  import lib from '@/api/co.lib'

  import mixAlert from '@/pages/mixins/mix.alert.js'
  import mixHomeBanner from '@/pages/mixins/home/mix.home.banner'
  import mixActivityDetail from '@/pages/mixins/activity/mix.activity.detail'

  export default {
    metaInfo: { title: '活动详情' },
    mixins: [
      mixAlert,
      mixHomeBanner,
      mixActivityDetail
    ],
    data () {
      return {
      }
    },
    created () {
      const name = api.page.activityDetail.name
      const params = JSON.parse(lib.cookie.get(name))

      this.initBannerParams({
        topicId: this.banTopicId,
        categoryId: this.banCategoryId
      })
      this.loadBanners()

      const actId = params.activityId || ''
      if (actId) {
        this.getActivity(actId)
      }
    }
  }
</script>
