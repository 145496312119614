
export default {
  data () {
    return {
      alertName: 'altName'
    }
  },
  methods: {
    hideAlert () {
      const ref = this.$refs[this.alertName]
      if (ref) {
        ref.hide()
      }
    },
    showAlert ({
      type = 'info',
      content = '',
      duration = 4000,
      closed = false,
      icon = ''
    }) {
      const ref = this.$refs[this.alertName]
      if (ref) {
        ref.show({
          type, content, duration, closed, icon
        })
      }
    },
    showSuccess (content) {
      this.showAlert({
        type: 'success',
        content
      })
    },
    showInfo (content) {
      this.showAlert({
        type: 'info',
        content
      })
    },
    showWarning (content) {
      this.showAlert({
        type: 'warning',
        content
      })
    },
    showError (content) {
      this.showAlert({
        type: 'error',
        content
      })
    }
  }
}
